import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import EastIcon from "@mui/icons-material/East";

import { isEmpty } from "../../../utils/validations";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import styles from "./MatchAmbassador.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import {
  createFriendlyTitle,
  createFreshChatPreview,
} from "../../../utils/common";
import { Button } from "../../atoms/Button";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import {
  setCurrentConversationId,
  setChatsList,
  setConversationInformation,
  createFreshChatRecord,
} from "../../../redux/reducers/chat/reducers";
import { ROUTES } from "../../../routes";

const MAX_VISIBLE_CHARS = 90;
const cx = createModuleStyleExtractor(styles);

export default function MatchAmbassador({
  matchMeModal = {},
  selectedSchoolId = null,
  setMatchMeModal = () => {},
}) {
  const chatStore = useSelector((store) => store.chatStore);
  const { chats = [] } = chatStore || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open = false, ambassadors = [] } = matchMeModal;

  const [selectedAmbassadorIndex, setSelectedAmbassadorIndex] = useState(0);
  const [seeMore, setSeeMore] = useState(false);

  const selectedAmbassador = useMemo(
    () => ambassadors[selectedAmbassadorIndex],
    [selectedAmbassadorIndex, ambassadors]
  );

  const handleClose = () => {
    setMatchMeModal({
      open: false,
      loadingAPI: false,
      ambassadors: [],
    });
    setSelectedAmbassadorIndex(0);
    setSeeMore(false);
  };

  const handleMatchAmbassador = () => {
    setSeeMore(false);
    if (selectedAmbassadorIndex === ambassadors?.length - 1) {
      setSelectedAmbassadorIndex(0);
    } else {
      setSelectedAmbassadorIndex((current) => ++current);
    }
  };

  const handleOnClickMessage = () => {
    // Check if conversation already present with selected user
    const conversationCheckIndex =
      chats && !isEmpty(chats)
        ? chats.findIndex((item) => item.receiver_id === selectedAmbassador.id)
        : -1;
    if (conversationCheckIndex !== -1) {
      const conversationId =
        chatStore.chats[conversationCheckIndex].conversation_id;
      Mixpanel.track("Chat initiated", {
        schoolID: selectedSchoolId,
        objId: selectedAmbassador.id,
        objClass: "User",
      });
      dispatch(setCurrentConversationId(conversationId));
      navigate(ROUTES.MESSAGES);
    } else {
      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        selectedAmbassador,
        randomConversationId
      );
      Mixpanel.track("Chat initiated", {
        schoolID: selectedSchoolId,
        objId: selectedAmbassador.id,
        objClass: "User",
      });
      dispatch(setChatsList({ chats: [preview, ...(chats || [])] }));
      dispatch(
        setConversationInformation({
          conversation,
          conversation_id: randomConversationId,
        })
      );
      dispatch(setCurrentConversationId(randomConversationId));
      dispatch(createFreshChatRecord({ id: randomConversationId }));
      navigate(ROUTES.MESSAGES);
    }
  };

  const userIntro =
    selectedAmbassador?.user_intro ||
    (() => {
      const {
        degree = "",
        major = "",
        school_name = "",
      } = selectedAmbassador || {};
      if (!degree && !major && !school_name) {
        return "I am currently pursuing my education.";
      }
      const degreePart = degree ? `my ${degree} degree` : "my degree";
      const majorPart = major ? ` in ${major}` : "";
      const schoolPart = school_name ? ` at ${school_name}` : "";

      return `I am currently pursuing ${[degreePart, majorPart, schoolPart]
        .filter(Boolean)
        .join("")}`;
    })();

  return (
    <div className={cx("m-match-ambassador")}>
      <Modal
        show={open}
        onClose={handleClose}
        centerAlign={false}
        preserveBodyScroll
      >
        <h2 className={cx("m-match-ambassador__header")}>
          Match with an ambassador
        </h2>
        <ModalBody>
          <div className={cx("m-match-ambassador__body")}>
            <h4 className={cx("m-match-ambassador__body__sub-header")}>
              Based on your profile we believe this ambassador will be the right
              fit for you!
            </h4>
            <CircularAvatar
              alt={selectedAmbassador?.full_name}
              name={selectedAmbassador?.full_name}
              src={selectedAmbassador?.user_profile}
              round={true}
              size={100}
            />
            <p className={cx("m-match-ambassador__body__name")}>
              {createFriendlyTitle(selectedAmbassador?.full_name)}
            </p>
            <p className={cx("m-match-ambassador__body__education")}>
              {selectedAmbassador?.major}, {selectedAmbassador?.graduation_year}
            </p>
            <div className={cx("m-match-ambassador__body__country")}>
              <img src={selectedAmbassador?.country_image} alt="flag" />
              <span>From {selectedAmbassador?.country_of_origin}</span>
            </div>
            <div className={cx("m-match-ambassador__body__intro")}>
              <p className={cx("m-match-ambassador__body__intro__text")}>
                {seeMore ? userIntro : userIntro?.slice(0, MAX_VISIBLE_CHARS)}
                {seeMore || userIntro?.length < MAX_VISIBLE_CHARS
                  ? " "
                  : "... "}
                {seeMore ? (
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSeeMore(false);
                    }}
                    aria-label="See less"
                  >
                    See less
                  </a>
                ) : (
                  userIntro?.length > MAX_VISIBLE_CHARS && (
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setSeeMore(true);
                      }}
                      aria-label="See more"
                    >
                      See more
                    </a>
                  )
                )}
              </p>
            </div>
            <div className={cx("m-match-ambassador__body__button")}>
              <Button onClick={handleOnClickMessage}>Start chatting</Button>
            </div>
            <p
              className={cx("m-match-ambassador__body__match")}
              onClick={handleMatchAmbassador}
            >
              Match me with another ambassador
              <EastIcon color="var(--primary-color)" />
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
